<template>
    <el-row class="account-permission">
        <el-col>
            <el-card
                :body-style="{ 'padding': permissionListVisible ? '0 0 20px 0' : '10px 20px 20px 20px', 'min-height': cardHeight + 'px' }">
                <template #header>
                    <el-row justify="space-between">
                        <div>
                            <span class="SubHead">{{ permissionListVisible ? '角色权限设置' : '角色权限管理' }}</span>
                            <span class="current-role" v-if="permissionListVisible">当前角色：{{ form.name }}</span>
                        </div>
                        <el-link type="primary" :underline="false"
                            @click="dialogVisible = true; dialogType = 'add'; form.id = null; form.name = ''"
                            v-if="!permissionListVisible">新增角色</el-link>
                        <el-link type="primary" :underline="false" v-else
                            @click="ruleList = JSON.parse(JSON.stringify(defaultRuleList))">恢复默认</el-link>
                    </el-row>
                </template>
                <search-form-content ref="searchFormContentRef" :form-items="{}" :fields="fields" :default-page-size="10"
                    :fetch-data="fetchData" v-if="!permissionListVisible">
                    <template #operations>
                        <el-table-column label="操作" align="left" header-align="left" width="290">
                            <template #default="{ row }">
                                <el-button type="text" class="operate-button" size="small"
                                    @click="editViewRule(row)">编辑查看权限</el-button>
                                <el-button type="text" class="operate-button" size="small" @click="editRoleName(row)"
                                    v-if="row.name !== '管理员' && row.name !== '企业成员'">编辑角色名称</el-button>
                                <el-button type="text" class="operate-button" size="small"
                                    @click="form.id = row.id; deleteDialogVisible = true" style="color: #F56C6C!important;"
                                    v-if="row.name !== '管理员' && row.name !== '企业成员'">删除</el-button>
                            </template>
                        </el-table-column>
                    </template>
                </search-form-content>
                <div class="permission-list" v-else>
                    <div class="list-header">
                        <div>
                            <span>功能名称</span><span>是否可见</span>
                        </div>
                    </div>
                    <div class="is-view-all-accounts">
                        <div>
                            <span>查看全部广告账户</span>
                            <div style="width: 64px;text-align: center;">
                                <el-switch v-model="viewAllAccountsAble" class="mt-2" inline-prompt :active-value="1"
                                    :inactive-value="0" />
                            </div>
                        </div>
                    </div>
                    <el-menu background-color="#fff" text-color="#595959" :collapse-transition="false"
                        active-text-color="#595959" :default-openeds="['1', '4', '5', '7', '8', '11', '13', '16', '17']">
                        <!-- 一级菜单 -->
                        <el-sub-menu :index="item.id.toString()" v-for="item in ruleList" :key="item.id"
                            popper-class="first-menu">
                            <template #title>
                                <div class="permission-list-item">
                                    <span>{{ item.name }}</span>
                                    <div @click.stop="">
                                        <el-switch v-model="item.is_rule" class="mt-2" style="margin-left: 24px"
                                            inline-prompt :active-icon="Check" :inactive-icon="Close" :active-value="1"
                                            :inactive-value="0" @change="switchChangeFather(item.id, item.is_rule)" />
                                    </div>
                                </div>
                            </template>
                            <div v-for="subItem in item.son" :key="subItem.id">
                                <!-- 二级菜单（没有三级菜单）-->
                                <el-menu-item :index="subItem.id.toString()" v-if="JSON.stringify(subItem.son) === '[]'">
                                    <div class="permission-list-item">
                                        <span>{{ subItem.name }}</span>
                                        <div @click.stop=""><el-switch v-model="subItem.is_rule" class="mt-2"
                                                style="margin-left: 24px" inline-prompt :active-icon="Check"
                                                :inactive-icon="Close" :active-value="1" :inactive-value="0"
                                                @change="switchChangeSon(subItem.id, subItem.is_rule)" /></div>
                                    </div>
                                </el-menu-item>
                                <!-- 二级菜单（有三级菜单）-->
                                <el-sub-menu v-if="JSON.stringify(subItem.son) !== '[]'" :index="subItem.id.toString()">
                                    <template #title>
                                        <div class="permission-list-item">
                                            <span>{{ subItem.name }}</span>
                                            <div @click.stop=""><el-switch v-model="subItem.is_rule" class="mt-2"
                                                    style="margin-left: 24px" inline-prompt :active-icon="Check"
                                                    :inactive-icon="Close" :active-value="1" :inactive-value="0"
                                                    @change="switchChangeFather(subItem.id, subItem.is_rule)" /></div>
                                        </div>
                                    </template>
                                    <div v-for="subSubItem in subItem.son" :key="subSubItem.id">
                                        <!-- 三级菜单（没有四级菜单）-->
                                        <el-menu-item :index="subSubItem.id.toString()" v-if="!subSubItem.son">
                                            <div class="permission-list-item">
                                                <span>{{ subSubItem.name }}</span>
                                                <div @click.stop=""><el-switch v-model="subSubItem.is_rule" class="mt-2"
                                                        style="margin-left: 24px" inline-prompt :active-icon="Check"
                                                        :inactive-icon="Close" :active-value="1" :inactive-value="0"
                                                        @change="switchChangeSon(subSubItem.id, subSubItem.is_rule)" />
                                                </div>
                                            </div>
                                        </el-menu-item>
                                        <!-- 三级菜单(有四级菜单) -->
                                        <el-sub-menu v-if="subSubItem.son" :index="subSubItem.id.toString()">
                                            <template #title>
                                                <div class="permission-list-item">
                                                    <span>{{ subSubItem.name }}</span>
                                                    <div @click.stop=""><el-switch v-model="subSubItem.is_rule" class="mt-2"
                                                            style="margin-left: 24px" inline-prompt :active-icon="Check"
                                                            :inactive-icon="Close" :active-value="1" :inactive-value="0"
                                                            @change="switchChangeFather(subSubItem.id, subSubItem.is_rule)" />
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- 四级菜单 -->
                                            <el-menu-item v-for="subSubSubItem in subSubItem.son" :key="subSubSubItem.id"
                                                :index="subSubSubItem.id.toString()">
                                                <div class="permission-list-item">
                                                    <span>{{ subSubSubItem.name }}</span>
                                                    <div @click.stop=""><el-switch v-model="subSubSubItem.is_rule"
                                                            class="mt-2" style="margin-left: 24px" inline-prompt
                                                            :active-icon="Check" :inactive-icon="Close" :active-value="1"
                                                            :inactive-value="0"
                                                            @change="switchChangeSon(subSubSubItem.id, subSubSubItem.is_rule)" />
                                                    </div>
                                                </div>
                                            </el-menu-item>
                                        </el-sub-menu>
                                    </div>
                                </el-sub-menu>
                            </div>
                        </el-sub-menu>
                    </el-menu>
                    <span class="list-footer">
                        <el-button @click="permissionListVisible = false; form.id = null" size="small">返回</el-button>
                        <el-button type="primary" size="small" @click="savePermissionList">保存</el-button>
                    </span>
                </div>
            </el-card>
        </el-col>
    </el-row>
    <el-dialog v-model="dialogVisible" :title="dialogType === 'add' ? '新增角色' : '编辑角色名称'" width="30%" top="20vh"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="account-permission-dialog">
        <div>
            <el-form label-position="left" label-width="100px" :model="form" :rules="rules" ref="ruleFormRef">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="form.name" size="small" placeholder="请输入角色名称" style="width: 85%;" />
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取消</el-button>
                <el-button type="primary" size="small" @click="handleRole">确认</el-button>
            </span>
        </template>
    </el-dialog>
    <div class="delete-dialog">
        <el-dialog v-model="deleteDialogVisible" width="450px" top="30vh">
            <div class="delete">
                <img src="@/assets/images/business/warning.png" alt="">
                <div class="delete-title">是否确定删除此角色？</div>
                <div class="delete-info">注意：删除后，此角色下的用户将转换为默认企业成员角色。</div>
                <div>
                    <el-button @click="deleteDialogVisible = false" size="small">取消</el-button>
                    <el-button type="danger" size="small" @click="handleDeleteRole">确认</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import { getRoleList, addRole, editRole, deleteRole, getRolePermission, editRolePermission } from '@/request/api/business'
import { Check, Close } from '@element-plus/icons-vue'

const { proxy } = getCurrentInstance()
const searchFormContentRef = ref(null)
const userInfo = ref(proxy.$store.getters.userInfo)
const cardHeight = ref(window.innerHeight - 180)
const ruleFormRef = ref(null)
const permissionListVisible = ref(false)
const dialogVisible = ref(false)
const deleteDialogVisible = ref(false)
const dialogType = ref('add')
const form = ref({
    id: null,
    name: ''
})

const rules = reactive({
    name: [
        { required: true, message: '请输入角色名称', trigger: 'blur' },
    ]
})

const fields = [
    { id: 'name', label: '角色名称', align: 'left' },
]

const defaultRuleList = ref([]) // 恢复默认的权限列表
const ruleList = ref([]) // 角色的权限列表
const viewAllAccountsAble = ref(0) // 是否可查看全部广告账户

const fetchData = async (params) => {
    let res = await getRoleList(params)

    return {
        page: {
            total: res.data && res.total ? res.total : 0,
            page: params.page ? params.page : 1,
            pagesize: params.pagesize ? params.pagesize : 10,
        },
        data: res.data && res.data.length > 0 ? res.data : [],
    }
}

const editViewRule = (row) => {
    form.value.id = row.id
    form.value.name = row.name
    permissionListVisible.value = true
    getRolePermission(row.id).then(res => {
        defaultRuleList.value = JSON.parse(JSON.stringify(res.data))
        ruleList.value = res.data
        viewAllAccountsAble.value = res.is_account
    })
}

/**
 * 递归遍历获取树形结构中所有选中的节点id
 * @param {Array} tree 
 */
const collectIds = (tree, result = []) => {
    for (const node of tree) {
        if (node.is_rule === 1) {
            result.push(node.id);
        }
        if (node.son && node.son.length > 0) {
            collectIds(node.son, result);
        }
    }
    return result.join(",");
}

const savePermissionList = () => {
    const rule_ids = collectIds(ruleList.value);
    editRolePermission(form.value.id, rule_ids, viewAllAccountsAble.value).then(res => {
        proxy.$message.success(res.msg)
        permissionListVisible.value = false
        searchFormContentRef.value.refreshData()
        form.value.id = null
    })
}

const editRoleName = (row) => {
    dialogVisible.value = true
    dialogType.value = 'edit'
    form.value.id = row.id
    form.value.name = row.name
}

const handleRole = async () => {
    if (dialogType.value === 'add') {
        await ruleFormRef.value.validate((valid) => {
            if (!valid) return
            addRole(form.value.name).then((res) => {
                proxy.$message.success(res.msg)
                ruleFormRef.value.resetFields()
                dialogVisible.value = false
                searchFormContentRef.value.refreshData()
            })
        })
    } else if (dialogType.value === 'edit') {
        await ruleFormRef.value.validate((valid) => {
            if (!valid) return
            editRole(form.value.name, form.value.id).then((res) => {
                proxy.$message.success(res.msg)
                ruleFormRef.value.resetFields()
                dialogVisible.value = false
                searchFormContentRef.value.refreshData()
            })
        })
    }
}

const handleDeleteRole = () => {
    deleteRole(form.value.id).then((res) => {
        if (res.code === 200) {
            proxy.$message.success('删除成功！')
            deleteDialogVisible.value = false
            searchFormContentRef.value.refreshData()
        }
    })
}

const switchChangeFather = (id, is_rule) => {
    const setRule = (item) => {
        item.is_rule = is_rule;
        if (item.son && item.son.length > 0) {
            item.son.forEach(subItem => {
                setRule(subItem);
            });
        }
    };

    ruleList.value.forEach(item => {
        if (item.id === id) {
            setRule(item);
        } else {
            item.son.forEach(subItem => {
                if (subItem.id === id) {
                    if (is_rule === 1) {
                        item.is_rule = is_rule;
                    }
                    setRule(subItem);
                } else if (subItem.son && subItem.son.length > 0) {
                    subItem.son.forEach(i => {
                        if (i.id === id) {
                            if (is_rule === 1) {
                                item.is_rule = is_rule;
                                subItem.is_rule = is_rule;
                            }
                            setRule(i);
                        }
                    });
                }
            });
        }
    });
};

const switchChangeSon = (id, is_rule) => {
    if (is_rule === 1) {
        ruleList.value.forEach(item => {
            item.son.forEach(subItem => {
                if (subItem.id === id) {
                    item.is_rule = 1
                }
                if (subItem.son && subItem.son.length > 0) {
                    subItem.son.forEach(i => {
                        if (i.id === id) {
                            subItem.is_rule = 1
                            item.is_rule = 1
                        }
                        if (i.son && i.son.length > 0) {
                            i.son.forEach(j => {
                                if (j.id === id) {
                                    i.is_rule = 1
                                    subItem.is_rule = 1
                                    item.is_rule = 1
                                }
                            })
                        }
                    })
                }
            })
        })
    }
}

</script>

<style lang="less">
.account-permission-dialog {
    .el-dialog__body {
        padding: 30px 40px 0 40px;
    }

    .el-dialog__footer {
        padding-bottom: 40px !important;
    }
}
</style>
<style lang="less" scoped>
.account-permission {
    .current-role {
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8C8C8C;
    }

    :deep(.el-table .el-table__cell.is-center) {
        text-align: left;
    }

    :deep(.el-table__row .el-table__cell:first-child) {
        padding-left: 20px;
    }

    :deep(.el-table__row td:first-child .cell) {
        color: #595959;
    }

    :deep(tr .cell) {
        padding-left: 0;
    }

    :deep(thead tr th .cell) {
        color: #262626;
        font-size: 14px;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
    }

    :deep(thead tr th:first-child .cell) {
        padding-left: 20px;
    }

    :deep(.el-row.is-justify-end) {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    :deep(.el-card__body>.el-row:first-child) {
        margin-bottom: 60px;
    }

    .operate-button {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
    }
}

.permission-list {
    padding-bottom: 50px;

    .list-header {
        width: 100%;
        height: 64px;
        background: #F5F7FA;
        border-bottom: 1px solid #EBEEF5;

        &>div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 160px 0 20px;

            span {
                line-height: 60px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #595959;
            }
        }
    }

    .is-view-all-accounts {
        width: 100%;
        height: 56px;
        background: #F5F7FA;
        border-bottom: 1px solid #EBEEF5;

        &>div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 160px 0 20px;

            span {
                line-height: 60px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #262626;
            }
        }
    }

    .permission-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 150px;
    }

    :deep(.el-sub-menu__title) {
        border-bottom: 1px solid #EBEEF5;
    }

    :deep(.el-menu-item) {
        border-bottom: 1px solid #EBEEF5;

        .permission-list-item {
            padding-right: 125px;
        }
    }

    :deep(.el-menu-item:hover) {
        background: #ffffff;
    }

    :deep(.el-menu>.el-sub-menu>.el-sub-menu__title:first-child) {
        background: #F5F7FA !important;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626 !important;
    }

    .list-footer {
        position: absolute;
        bottom: 20px;
        right: 20px;

        :deep(.el-button) {
            margin-left: 16px;
        }
    }
}

.delete-dialog {
    :deep(.el-dialog__body) {
        padding-top: 5px;
    }

    .delete {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
        }

        .delete-title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            margin-bottom: 16px;
        }

        .delete-info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #595959;
            margin-bottom: 40px;
        }

        :deep(.el-button+.el-button) {
            margin-left: 16px;
        }
    }
}
</style>